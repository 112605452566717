import { DoneElement, userBlockWrapper } from "../../app";

export class Heading extends DoneElement {
  block: string;
  id: string;
  format: string;

  constructor() {
    super();
    this.inner = this.innerHTML;
    this.block = "heading";
    this.readonly = false;
    this.format = this.getAttribute("done-format") || "h2";
  }

  connectedCallback() {
    this.render();
  }

  render() {
    this.innerHTML = userBlockWrapper(`
        <${this.format} contenteditable>
          ${this.inner !== "" ? this.inner : ""}
        </${this.format}>
      `);
  }
}

customElements.define(`done-block-heading`, Heading);
