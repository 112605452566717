import { DoneElement, userBlockWrapper } from "../../app";

export class Text extends DoneElement {
  block: string;
  id: string;

  constructor() {
    super();
    this.inner = this.innerHTML;
    this.block = "text";
    this.readonly = false;
  }

  connectedCallback() {
    this.render();
    
  }

  render() {
    this.innerHTML = userBlockWrapper(`
        <p contenteditable>
          ${this.inner !== "" ? this.inner : ""}
        </p>
      `);
  }
}

customElements.define(`done-block-text`, Text);
