import { done, DoneElement } from "../../app";

/**
 * Class representing a view in the application.
 * @extends DoneElement
 */
export class ViewHeader extends DoneElement {
  src: string;

  constructor() {
    super();
    this.src = this.getAttribute("done-src") || "null";
  }

  connectedCallback() {
    this.render();
  }

  render() {
    const pageId = this.getCurrentPageIdFromUrl() || "home";
    const pageData = done.userContent.find((page) => page.id === pageId) || undefined;
    // console.log(pageData.pageContent[0].id);

    if (!pageData || !pageData.pageContent) {
      return;
    }

    this.innerHTML = `
          <header class="done-view-header">
            ${
              pageData.src !== null && pageData.src !== undefined
                ? ` <figure class="done-image">
                      <img src=${pageData.image.src || "https://placehold.co/150"} alt="${pageData.image.alt || "alt"}">
                    </figure>`
                : ""
            }
            <section class="meta">
              <h1 class="" contenteditable>${pageData.title}</h1>
              <p class="done-description" contenteditable>${pageData.description}</p>
              <hr>
              <table class="done-meta-table">
                <th>
                  <tr>
                    <td>Time</td>
                    <td>Date</td> 
                    <td>Parent</td>
                  </tr>
                </th>
                <tr>
                  <td>${pageData.time}</td>
                  <td>${pageData.date}</td>
                  ${pageData.parentPage !== null ? `<td><button data-done-open-page="${pageData.parentPage}">${pageData.parentPage}</button></td>` : "<td>No Parent</td>"}
                </tr>
              </table>
            </section>
            <section class="linked-children">
                        <p>Child Pages</p>
                        <div class="child-page">
              <!--
                Map All Child Pages Here anas link to them
               -->
               ${done.userContent
                 .filter((page) => page.parentPage === pageData.id)
                 .map((page) => {
                   return `
                    <button data-done-open-page="${page.id}">${page.title}</button>
                      `;
                 })
                 .join("")}
                 </div>
              </section>
              <hr>
          </header>
    `;
  }
}

customElements.define("done-view-header", ViewHeader);
