import { DoneElement, userBlockWrapper } from "../../app";

export class Link extends DoneElement {
  block: string;
  id: string;
  href: any;

  constructor() {
    super();
    this.inner = this.innerHTML;
    this.block = "link";
    this.href = this.getAttribute("done-href") || "https://orf.at";
    this.readonly = false;
    // Get id from local storage OR generate a new one
  }

  connectedCallback() {
    this.render();
    
  }

  render() {
    this.innerHTML = userBlockWrapper(`
        <a class="done-link" href=${this.href} contenteditable>
          ${this.inner !== "" ? this.inner : ""}
        </a>
      `);
  }
}

customElements.define(`done-block-link`, Link);
