import { done, DoneElement, userBlockWrapper } from "../../app";

export class Image extends DoneElement {
  block: string;
  id: string;
  src: string;  
  alt: string;


  constructor() {
    super();
    this.inner = this.innerHTML;
    this.block = "image";
    this.src = this.getAttribute("done-src") ||"https://via.placeholder.com/150x75";
    this.alt = this.getAttribute("done-alt") || '';
    this.readonly = false;
  }

  connectedCallback() {
    this.render();
  }

  render() {
    this.innerHTML = userBlockWrapper(`
        <figure>
          <img contenteditable
            src="${this.src}" 
            alt="${this.alt !== null ? this.inner : this.alt}">
            <figcaption contenteditable>
              ${this.inner !== "" ? this.inner : ""}
            </figcaption>
        </figure>
      `);
  }
}

customElements.define(`done-block-image`, Image);
