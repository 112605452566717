/**
 * @param {string} block
 * @returns {string}
 * @typedef {object} userBlockWrapper
 */
export type userBlockWrapper = {
  name: string;
};

export function userBlockWrapper(block: string) {
  return `
  <div>
    <done-keyboard-controls>
      <section 
        class="done-user-block" 
        data-done-user-inserted-block 
        data-done-user-block>
        <div class="done-user-block-inner">
          ${block}
        </div>
      </section>
      <button data-done-delete-nearest-user-block class="">Delete Block</button>
    </done-keyboard-conrtols>
  </div>
  `.trim();
}
