import { done } from "../app";
import { savePageContentOnBlur } from "./savePageContentOnBlur";

/**
 * Class representing a custom HTML element.
 * @extends HTMLElement
 */
export class DoneElement extends HTMLElement {
  inner: string;
  readonly: boolean;
  id: string;
  src: string;
  done: any;

  constructor() {
    super();
    this.inner = this.innerHTML;
    this.id = Math.random().toString(36).substr(2, 9);
    this.classList.add("done-block");
    this.setAttribute("data-done-block", "");
    this.readonly = true;
    this.src = this.getAttribute("done-src") || "";
    this.init();
    this.done = done;
  }

  connectedCallback() {
    // Ensure this runs after the DOM is fully rendered
    window.addEventListener("DOMContentLoaded", () => {
      this.setContentEditable();
      savePageContentOnBlur();
    });
  }

  init() {
    const addPageButton = document.querySelector("button[data-done-add-page]");
    if (addPageButton) {
      addPageButton.removeEventListener("click", this.addPage); // Ensure no duplicate listeners
      addPageButton.addEventListener("click", () => this.addPage());
    }
  }

  deleteBlock() {
    // the button with the attroibute data-done-delete-nearest-user-block shiould delete the nearest (or parent block) with the class done-user-block
    const deleteBlockButton = document.querySelectorAll("[data-done-delete-nearest-user-block]");
    deleteBlockButton.forEach((button) => {
      button.addEventListener("click", () => {
        console.log("delete block button clicked");
        const block = button.closest(".done-user-block");
        if (block) {
          block.remove();
          block.innerHTML = "";
        }
      });
    });
  }

  setContentEditable() {
    if (!this.readonly) {
      const doneBlockInner = this.querySelector(".done-user-block-inner > *");
      if (doneBlockInner) {
        doneBlockInner.setAttribute("contenteditable", "true");
      } else {
        console.warn("No .done-block-inner element found to set contenteditable.");
      }
    }
  }

  reRenderIfViewChanged() {
    let view = document.querySelector("done-view");
    if (view) {
      let observer = new MutationObserver(() => {
        this.render();
        /* Update only the user content */
        let done = JSON.parse(localStorage.getItem("done") || "");
        console.log(done.userContent);
      });
      observer.observe(view, { childList: true, subtree: true });
      return done;
    }
  }

  openPageInView = () => {
    const buttons = document.querySelectorAll("button[data-done-open-page]");
    buttons.forEach((button) => {
      button.addEventListener("click", (e) => {
        console.log("button clicked");
        const pageId = button.getAttribute("data-done-open-page");
        const url = new URL(window.location.href);
        url.searchParams.set("page", pageId);
        window.history.pushState({}, "", url);
        const view = document.querySelector("done-view") as any;
        view.render();
      });
    });
  };

  getOrSetStoredDoneObject() {
    if (!localStorage.getItem("done")) {
      localStorage.setItem("done", JSON.stringify(done));
    }
  }

  setCurrentPageBlock = () => {
    const view = document.querySelector("done-view") as any;
    const pageId = view.getCurrentPageIdFromUrl() || "home";
    const pageData = done.userContent.find((page) => page.id === pageId);
    if (pageData) {
      const blockId = Math.random().toString(36).substr(2, 9);
      const blockHash = Math.random().toString(36).substr(2, 9);
      const block = {
        id: blockId,
        hash: blockHash,
        type: "text",
        content: "New block",
      };
      pageData.pageContent.push(block);
      view.render();
    }
  };

  addPage = () => {
    let id = Math.random().toString(36).substr(2, 9);
    console.log("Adding page with ID:", id);
    let page = {
      id: id,
      title: "New Page " + id,
      parentPage: null,
      image: {
        src: "https://placehold.co/150",
        alt: "An image",
      },
      pageContent: [],
    };
    done.userContent.push(page as any);

    localStorage.setItem("done", JSON.stringify(done));
    let render = document.querySelector("done-app") as any;
    if (render) {
      setTimeout(() => {
        render.render();
      }, 200);
    }
  };

  addBlockToCurrentPage = () => {
    const buttons = document.querySelectorAll("button[data-done-add-block-to-current-page]");
    buttons.forEach((button) => {
      button.addEventListener("click", (e) => {
        const view = document.querySelector("done-view") as any;
        const pageId = view.getCurrentPageIdFromUrl() || "home";
        const pageData = done.userContent.find((page) => page.id === pageId);
        if (pageData) {
          const blockId = Math.random().toString(36).substr(2, 9);
          const blockHash = Math.random().toString(36).substr(2, 9);
          const block = {
            id: blockId,
            hash: blockHash,
            type: "text",
            content: "New block",
          };
          pageData.pageContent.push(block);
          view.render();
          localStorage.setItem("done", JSON.stringify(done));
        }
      });
    });
  };

  getCurrentPageIdFromUrl = () => {
    const url = new URL(window.location.href);
    return url.searchParams.get("page");
  };
}
