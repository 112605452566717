import { done, DoneElement } from "../../app";
import { userBlockWrapper } from "../../scripts/userBlockWrapper";

export class Sidebar extends DoneElement {
  constructor() {
    super();
    this.handleAddPage = this.handleAddPage.bind(this);
  }

  connectedCallback() {
    this.render();
    this.openPageInView();
  }

  handleAddPage() {
    this.addPage();
  }
  render() {
    this.innerHTML = `
      <aside class="done-sidebar">
        <done-sidebar-header></done-sidebar-header>
        <ul>
        ${done.userContent
          .map((page: any) => {
            if (page.parentPage !== null) return "";
            return `
              <li>
                <button
                  class="full align-left done-sidebar-button"
                  data-done-open-page="${page.id}">
                  ${page.title}
                  <div>
                    ${done.userContent
                      .map((subPage: any) => {
                        if (subPage.parentPage !== page.id) return "";
                        return `
                          <button
                            class="full align-left done-sidebar-button"
                            data-done-open-page="${subPage.id}"
                            data-done-is-child-of="${page.id}">
                            ${subPage.title}
                          </button>
                        `;
                      })
                      .join("")}
                  </div>
                </button>
              </li>
            `;
          })
          .join("")}
        </ul>
        <button data-done-add-page>Add Page</button>
      </aside>
      ${this.inner}
    `;

    const addPageButton = this.querySelector("button[data-done-add-page]");
    if (addPageButton) {
      // addPageButton.removeEventListener("click", this.handleAddPage); // Ensure no duplicate listeners
      addPageButton.addEventListener("click", this.handleAddPage);
    }
  }
}

customElements.define(`done-sidebar`, Sidebar);
