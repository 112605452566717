import { DoneElement, done } from "../../app";

export class AddBlockButton extends DoneElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.render();
  }

  addBlock() {
    const addBlockButton = document.querySelectorAll("[data-done-add-block-to-current-page]");
    addBlockButton.forEach((button) => {
      button.addEventListener("click", () => {
        this.addBlockToCurrentPage();
      });
    });
  }

  render() {
    this.innerHTML = `
      <button class="full done-add-block" data-done-add-block-to-current-page>
        ${this.inner !== "" ? this.inner : "+ Add Block"}
      </button>
    `;
    this.addBlockToCurrentPage();
    this.addBlock();
  }
}

customElements.define(`done-add-block-button`, AddBlockButton);
