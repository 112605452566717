import { done, DoneElement } from "../../app";

/**
 * Class representing a view in the application.
 * @extends DoneElement
 */
export class View extends DoneElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.render();
  }

  render() {
    const pageId = this.getCurrentPageIdFromUrl() || "home";
    const pageData = done.userContent.find(page => page.id === pageId) || undefined;
    // console.log(pageData.pageContent[0].id);

    if (!pageData || !pageData.pageContent) {
      console.error(`No page content found for page ID: ${pageId}`);
      this.innerHTML = `
            <done-view-header></done-view-header>
            <done-user-container>
              <div class="error-message">Page content not available for the specified page ID.</div>
            </done-user-container>
      `;
      return;
    }

    this.innerHTML = `
        <section class="done-view">
          <done-view-header></done-view-header>
          <done-user-container class="done-view-user-container done-block">
            ${this.getCurrentPageIdFromUrl() ? `<div id="${this.getCurrentPageIdFromUrl()}"></div>` : `<div id="home"></div>`}
            <div data-done-draggable-container>
              ${pageData.pageContent
                .map((block: any) => {
                  return `
                    <done-block-${block.type} 
                      id="${block.id}-${block.hash}" data-done-draggable-item>
                        ${block.content}
                    </done-block-${block.type}>
                  `;
                })
                .join("")}
            </div>
            <done-add-block-button></done-add-block-button>
          </done-user-container>
        </section>
    `;
  }
}

customElements.define("done-view", View);
