import { Draggable } from "@shopify/draggable";

export const draggable = () => {
  let draggableContainers = document.querySelectorAll("[data-done-draggable-container]");

  draggableContainers.forEach((container) => {
    const draggable = new Draggable(container, {
      draggable: "[data-done-draggable-item]",
      handle: "[data-done-draggable-item]",
      delay: 100, // Optional delay before dragging starts
      mirror: {
        constrainDimensions: true,
      },
    });

    draggable.on("drag:start", (event) => {
      const draggedElement = event.source;
      draggedElement.classList.add("dragging");
      // Add a custom class to the dragged element
    });
    
    draggable.on("drag:move", (event) => {
      const draggedElement = event.source;
      const dropTarget = event.over;
      if (dropTarget) {
        dropTarget.classList.add("drop-hover");
        // Highlight the drop target when the dragged element is over it
      }
    });
    
    draggable.on("drag:stop", (event) => {
      const draggedElement = event.source;
      draggedElement.classList.remove("dragging");
      const dropTarget = event.over;
      if (dropTarget) {
        dropTarget.classList.remove("drop-hover");
        // Remove any drop target highlights
        // Perform additional actions based on the drop target or drag result
      }
    });
  });
};
