import { DoneElement, userBlockWrapper } from "../../app";

export class Quote extends DoneElement {
  block: string;
  id: string;
  cite: any;

  constructor() {
    super();
    this.inner = this.innerHTML;
    this.block = "quote";
    this.cite = this.getAttribute("done-cite");
    this.readonly = false;
  }

  connectedCallback() {
    this.render();
  }

  render() {
    this.innerHTML = userBlockWrapper(`
        <blockquote>
          <span contenteditable>${this.inner}</span>
          ${this.cite !== null ? `<cite contenteditable>${this.cite}</cite>` : ""}
        </blockquote>
      `);
  }
}

customElements.define(`done-block-quote`, Quote);
