import { done, DoneElement, draggable, savePageContentOnBlur } from "../../app";

export class App extends DoneElement {
  readonly: boolean;
  constructor() {
    super();
  }

  connectedCallback() {
    // save the done object to local storage
    this.getOrSetStoredDoneObject();
    const storedDone = JSON.parse(localStorage.getItem("done") || "{}");
    if (storedDone && storedDone.userContent) {
      done.userContent = storedDone.userContent;
    }
    this.render();
    savePageContentOnBlur();
    draggable();
  }

  render() {
    this.innerHTML = `
    <section class="done-app">
        <done-sidebar 
          class="done-left-pane"
          data-done-resizeable
          data-done-resizeable-edge="inline-start">
        </done-sidebar>
      <main class="done-main-area">
        <done-view></done-view>
      </main>
        <done-table-of-contents
          class="done-toc"
          data-done-resizeable
          data-done-resizeable-edge="inline-start">
          <!-- 
          Dynamically render a Table of Contents based on h1-h6 of the <done-user-container> contents
          -->
        </done-table-of-contents>
      </main>
    </section>
    `;
  }
}

customElements.define(`done-app`, App);
