import { done } from "./config";
import { draggable } from "./scripts/draggable";
import { savePageContentOnBlur } from "./scripts/savePageContentOnBlur";
// Functions
import { userBlockWrapper } from "./scripts/userBlockWrapper";
// Components & Elements
import { DoneElement } from "./scripts/DoneElement";
import { App } from "./components/app/App";
import { View } from "./components/app/View";
import { ViewHeader } from "./components/app/ViewHeader";
import { Sidebar } from "./components/app/Sidebar";
import { SidebarHeader } from "./components/app/SidebarHeader";
import { TableOfContents } from "./components/app/TableOfContents";
import { AddBlockButton } from "./components/app/ButtonAddBlock";
// User Components
import { Text } from "./components/user/Text";
import { Heading } from "./components/user/Heading";
import { Link } from "./components/user/Link";
import { Image } from "./components/user/Image";
import { Quote } from "./components/user/Quote";
import { Code } from "./components/user/Code";

savePageContentOnBlur();

export {
  // Global Object
  done,
  draggable,
  savePageContentOnBlur,
  // Functions
  userBlockWrapper,
  DoneElement,
  // App COmponents
  App,
  View,
  ViewHeader,
  Sidebar,
  SidebarHeader,
  TableOfContents,
  AddBlockButton,
  // User Components
  Text,
  Heading,
  Link,
  Image,
  Quote,
  Code
};
