import { DoneElement, userBlockWrapper } from "../../app";

export class Code extends DoneElement {
  block: string;
  id: string;
  cite: any;
  isMultiline: boolean;

  constructor() {
    super();
    this.inner = this.innerHTML;
    this.block = "code";
    this.readonly = false;
    this.isMultiline = this.innerHTML.includes("\n") && this.getAttribute("done-multiline") !== "false" ? true : false;
  }

  connectedCallback() {
    this.render();
  }

  render() {
    this.innerHTML = userBlockWrapper(`
        <code>
          ${this.isMultiline !== true ? `<pre>` : ``}
            <span contenteditable>${this.inner}</span>
          ${this.isMultiline !== true ? `<pre>` : ``}
        </code>
      `);
  }
}

customElements.define(`done-block-code`, Code);
