import { DoneElement, done } from "../../app";

export class SidebarHeader extends DoneElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.render();
  }

  render() {
    this.innerHTML = `
      <header class="sidebar-header">
        <p class="app-title">
          ${done.config.appName}
        </p>
      </header>
    `;
  }
}

customElements.define(`done-sidebar-header`, SidebarHeader);
