import { done, DoneElement } from "../../app";

export class TableOfContents extends DoneElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.render();
    this.reRenderIfViewChanged();
  }

  render() {
    // Wait for the done-user-container to be available and fully rendered
    setTimeout(() => {
      let userContainer = document.querySelector("done-user-container");
      if (userContainer) {
        let headers = userContainer.querySelectorAll("h1, h2, h3, h4, h5, h6");
        let tocContent = Array.from(headers)
          .map((header: Element) => {
            let id = header.id || header.textContent?.replace(/ /g, "").toLowerCase().trim();
            if (!header.id) header.id = id; // Assign id if not already present
            return `
          <div 
            data-done-toc-item="${header.id}" 
            data-done-toc-item-format="${header.nodeName}">
            <a href="#${header.id}">${header.textContent}</a>
          </div>`;
          })
          .join("");
        console.log(tocContent);
        this.innerHTML = `
          <nav class="table-of-contents">
            <p>Table of Contents</p>
            ${tocContent}
          </nav>
        `;
      }
    }, 0);
  }
}

customElements.define("done-table-of-contents", TableOfContents);
