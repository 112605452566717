let config = {
  appName: `done`,
  theme: `light`,
  fontSize: `1.25rem`,
  language: `en`,
  version: `0.0.1`,
  meta: {
    date: `2021-09-01`,
    time: `08:51:23UTC`,
    description: `A notes taking app`,
  },
};

export let done = {
  config,
  userContent: [
    {
      title: `Home`,
      icon: `https://placehold.co/50`,
      id: `home`,
      hash: `126EF12C4DE556`,

      date: new Date().toISOString().split(`T`)[0],
      time: `08:52:23UTC`,
      description: `Your Start Page`,
      parentPage: null,
      image: {
        src: `https://via.placeholder.com/150`,
        alt: `An image`,
      },
      pageContent: [
        {
          id:`12EF34GE5`,
          type: `heading`,
          format: 1,
          content: `Headline`,
        },
        {
          id:`09GFFE636`,
          type: `text`,
          content: `This is a text block`,
        },
        {
          id:`98934G54F`,
          type: `image`,
          content: `This is an image block`,
          src: `https://via.placeholder.com/150`,
          alt: `An image`,
        },
        {
          id:`12EF34GE5`,
          type: `heading`,
          format: 1,
          content: `Headline2`,
        },
        {
          id:`98934GE56`,
          type: `quote`,
          content: `This is a quote block`,
          cite: `Jack Kerouac`,
        },
        {
          id:`9893DFE56`,
          type: `link`,
          url: `https://orf.at`,
          content: `This is a link block`,
          target: `_blank`,
        },
      ],
    },
    {
      title: `Project`,
      icon: `https://placehold.co/50`,
      id: `project`,
      hash: `987EFG32778`,
      date: new Date().toISOString().split(`T`)[0],
      time: `08:52:23UTC`,
      description: `Your Project Page`,
      parentPage: `home`,
      image: {
        src: `https://via.placeholder.com/150`,
        alt: `An image`,
      },
      pageContent: [
        {
          id:`12SW3GE56`,
          type: `text`,
          content: `This is a text block`,
        },
        {
          id:`12SEEGE56`,
          type: `code`,
          content: `This is a code block`,
        },
        {
          id:`12EF34FB5`,
          type: `image`,
          content: `This is an image block`,
          src: `https://via.placeholder.com/150`,
          alt: `An image`,
        },
        {
          id:`134F34GE5`,
          type: `quote`,
          content: `This is a quote block`,
          cite: `Jack Kerouac`,
        },
        {
          id:`12903GE56`,
          type: `link`,
          url: `https://orf.at`,
          content: `This is a link block`,
          target: `_blank`,
        },
      ],
    },
  ],
};
