import { done } from "../app";
export const savePageContentOnBlur = () => {
  const blocks = document.querySelectorAll("[contenteditable]");
  console.log("blocks", blocks);
  blocks.forEach((block) => {
    block.addEventListener("blur", (e) => {
      console.log("blur event triggered");
      const view = document.querySelector("done-view") as any;
      const pageId = view.getCurrentPageIdFromUrl() || "home";
      console.log("pageId", pageId);
      const pageData = done.userContent.find((page) => page.id === pageId);
      console.log("pageData", pageData);
      if (pageData) {
        const blockId = block.getAttribute("data-block-id");
        console.log("blockId", blockId);
        const blockData = pageData.pageContent.find((b: any) => b.id === blockId);
        console.log("blockData", blockData);
        if (blockData) {
          blockData.content = block.innerHTML;
          localStorage.setItem("done", JSON.stringify(done));
          console.log("Content saved:", done);
        } else {
          console.log("Block data not found.");
        }
      } else {
        console.log("Page data not found.");
      }
    });
  });
};
